import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/header-img.svg";


export const Footer = () => {
  return (
    <footer className="footer pt-4">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
          <a href="/"><img src={logo} alt="Logo" /></a>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <p>Copyright 2024. SLERF ON DEGEN CHAIN! </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
