import { Container} from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  return (
    <section className="project">
      <Container>
              <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInBottomLeft" : ""}>
                <h2 className="pt-44" id="projects">Chart</h2>
              </div>}
        </TrackVisibility>

      <div className="ratio ratio-16x9">
                  <iframe src="https://dexscreener.com/degenchain/0x04cc9c818eadc41864f53e8a80068b0abd678393" title="dexscreener" allowfullscreen></iframe>
              </div>          <br /><br />

              

      </Container>
    </section>
    
  )
}


